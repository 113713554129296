import React from 'react';

import Layout from '@common/Layout';
import Navbar from '@common/Navbar';

import Header from '@sections/Header';
import Features from '@sections/Features'
// import About from '@sections/About';
import About from '@sections/About'
// import Brands from '@sections/Brands';
import Pricing from '@sections/Pricing'
// import Team from '@sections/Team';
// import Faq from '@sections/Faq';
import C2A from '@sections/C2A'
import Footer from '@sections/Footer';

const IndexPage = () => (
  <Layout>
    <Navbar />
    <Header />
    <Pricing/>
    <Features/>
    {/* <Team /> */}
    <About />
    {/* <Brands /> */}
    <C2A />
    <Footer />
  </Layout>
);

export default IndexPage;
