import { Section, Container } from '@components/global';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import React from 'react';

// import ExternalLink from '@common/ExternalLink';

// import { ReactComponent as AirbnbLogo } from '@images/logos/airbnb.svg';
// import { ReactComponent as AppleMusicLogo } from '@images/logos/apple-music.svg';
// import { ReactComponent as CokeLogo } from '@images/logos/coca-cola.svg';
// import { ReactComponent as NodeLogo } from '@images/logos/nodejs.svg';
// import { ReactComponent as NikeLogo } from '@images/logos/nike.svg';
// import { ReactComponent as InstagramLogo } from '@images/logos/instagram.svg';

// const LOGOS = [
//   {
//     logo: AirbnbLogo,
//     link: 'https://airbnb.io',
//   },
//   {
//     logo: AppleMusicLogo,
//     link: 'https://www.apple.com/in/music/',
//   },
//   {
//     logo: CokeLogo,
//     link: 'https://coca-cola.com',
//   },
//   {
//     logo: NodeLogo,
//     link: 'https://nodejs.org',
//   },
//   {
//     logo: NikeLogo,
//     link: 'https://nike.com',
//   },
//   {
//     logo: InstagramLogo,
//     link: 'https://instagram.com',
//   },
// ];

const UsedBy = () => (
  <StaticQuery
    query={graphql`
      query {
        art_story: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "team_works" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
    <Div1  >
      {/* <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/> */}

      <Section  accent id="grow">
      <White1></White1>
      <svg id="grow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#fdfdfd" fill-opacity="1" d="M0,128L120,106.7C240,85,480,43,720,42.7C960,43,1200,85,1320,106.7L1440,128L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"></path></svg>
        <StyledContainer>
          {/* <div>
            <h1>Keep all your orders in one place</h1>
            <LogoGrid>
              {LOGOS.map(({ logo, link }) => (
                <ExternalLink key={link} href={link}>
                  {logo()}
                </ExternalLink>
              ))}
            </LogoGrid>
          </div>
          <Art>
            <Img fluid={data.art_story.childImageSharp.fluid} />
          </Art> */}


          <Grid >
			<Art>
				<Img fluid={data.art_story.childImageSharp.fluid} />
			</Art>
            
			<div>
				<h2>Grow With Confidence</h2>
				<p>Keep tabs on your network of chargers with the operator tools suite</p>
				<Item>
					<svg width="25" height="25" viewBox="0 0 103 103" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M94.8514 47.5619V51.5678C94.8461 60.9575 91.8056 70.0939 86.1835 77.6144C80.5614 85.1349 72.6588 90.6366 63.6545 93.2989C54.6501 95.9612 45.0264 95.6415 36.2186 92.3875C27.4108 89.1334 19.8908 83.1194 14.7802 75.2424C9.66961 67.3653 7.2422 58.0473 7.86001 48.6779C8.47782 39.3086 12.1078 30.39 18.2084 23.2522C24.3091 16.1144 32.5537 11.1399 41.7125 9.07059C50.8713 7.00127 60.4537 7.94801 69.0305 11.7696" stroke="#000" stroke-width="14.4615" stroke-linecap="round" stroke-linejoin="round"/><path d="M94.8519 16.7344L51.309 60.3208L38.2461 47.2579" stroke="#000" stroke-width="14.4615" stroke-linecap="round" stroke-linejoin="round"/></svg>
					<h4>Comprehensive web portal that allows you to manage and monitor fleets of chargers from a single location.</h4>
				</Item>
				<Item>
					<svg width="25" height="25" viewBox="0 0 103 103" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M94.8514 47.5619V51.5678C94.8461 60.9575 91.8056 70.0939 86.1835 77.6144C80.5614 85.1349 72.6588 90.6366 63.6545 93.2989C54.6501 95.9612 45.0264 95.6415 36.2186 92.3875C27.4108 89.1334 19.8908 83.1194 14.7802 75.2424C9.66961 67.3653 7.2422 58.0473 7.86001 48.6779C8.47782 39.3086 12.1078 30.39 18.2084 23.2522C24.3091 16.1144 32.5537 11.1399 41.7125 9.07059C50.8713 7.00127 60.4537 7.94801 69.0305 11.7696" stroke="#000" stroke-width="14.4615" stroke-linecap="round" stroke-linejoin="round"/><path d="M94.8519 16.7344L51.309 60.3208L38.2461 47.2579" stroke="#000" stroke-width="14.4615" stroke-linecap="round" stroke-linejoin="round"/></svg>
					<h4>Powerful analytics from the whole network down to a single session</h4>
				</Item>
				<Item>
					<svg width="25" height="25" viewBox="0 0 103 103" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M94.8514 47.5619V51.5678C94.8461 60.9575 91.8056 70.0939 86.1835 77.6144C80.5614 85.1349 72.6588 90.6366 63.6545 93.2989C54.6501 95.9612 45.0264 95.6415 36.2186 92.3875C27.4108 89.1334 19.8908 83.1194 14.7802 75.2424C9.66961 67.3653 7.2422 58.0473 7.86001 48.6779C8.47782 39.3086 12.1078 30.39 18.2084 23.2522C24.3091 16.1144 32.5537 11.1399 41.7125 9.07059C50.8713 7.00127 60.4537 7.94801 69.0305 11.7696" stroke="#000" stroke-width="14.4615" stroke-linecap="round" stroke-linejoin="round"/><path d="M94.8519 16.7344L51.309 60.3208L38.2461 47.2579" stroke="#000" stroke-width="14.4615" stroke-linecap="round" stroke-linejoin="round"/></svg>
					<h4>Stay ontop of energy usage and time spent at charging stations with inegrated metrics</h4>
				</Item>
            </div>
          </Grid>
        </StyledContainer>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#fdfdfd" fill-opacity="1" d="M0,96L120,106.7C240,117,480,139,720,138.7C960,139,1200,117,1320,106.7L1440,96L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path></svg>
      <White></White>
      </Section>
      </Div1>
    )}
  />
);


const Div1 = styled.div`
//  margin-top: -1850px;

 @media (max-width: ${props => props.theme.screen.md}) {
//   margin-top: -2950px;
}
`;

const White = styled.div`
  margin-top: -10px;
  margin-bottom: -150px;
  width; 100vw;
  height: 100px;
  background-color: ${props => props.theme.color.ls.white};  
`;

const White1 = styled.div`
  margin-top: -150px;
  width; 100vw;
  height: 100px;
  background-color: ${props => props.theme.color.ls.white};  
`;


// const LogoGrid = styled.div`
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   grid-gap: 64px;
//   justify-items: center;
//   margin-top: 96px;

//   a {
//     svg {
//       width: 100%;
//     }
//   }

//   @media (max-width: ${props => props.theme.screen.sm}) {
//     grid-template-columns: 1fr;
//   }
// `;

const Item = styled.div`
color: ${props => props.theme.color.ls.black};
display: grid;
grid-template-columns: 50px 1fr;
grid-gap: 0px;
text-align: left;
align-items: center;
justify-items: start;
margin: 20px 0px;

`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: flex-end;
  position: relative;

  @media (max-width: ${props => props.theme.screen.md}) {
    justify-content: center;
  }
`;

const Grid = styled.div`
  z-index: 1;
  width: 70vw;
  color: ${props => props.theme.color.ls.black};
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-gap: 40px;
  text-align: left;
  align-items: center;
  justify-items: center;
  margin: 24px 0;

  ${props =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 2fr 3fr;
  `}

  h2 {
    margin-bottom: 16px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    width: 80vw;

    margin-top: 300px;

    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }

    ${props =>
      props.inverse &&
      `
        ${Art} {
          order: 2;
        }
    `}
  }
`;

const Art = styled.figure`
  margin: 0;
  max-width: 380px;
  width: 100%;
`;

// const Art = styled.figure`
//   width: 600px;
//   position: absolute;
//   top: -12%;
//   right: 50%;

//   @media (max-width: ${props => props.theme.screen.lg}) {
//     top: 0;
//     right: 65%;
//     width: 500px;
//   }

//   @media (max-width: ${props => props.theme.screen.md}) {
//     display: none;
//   }
// `;

export default UsedBy;
