import { Section, Container } from '@components/global';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import React from 'react';

const Team = () => (
  <StaticQuery
    query={graphql`
		query {
			art_story: file(
			sourceInstanceName: { eq: "art" }
			name: { eq: "tell_story" }
			) {
			childImageSharp {
				fluid(maxWidth: 1200) {
				...GatsbyImageSharpFluid_withWebp_tracedSVG
				}
			}
			}
		}
    `}
    render={data => (
      <Div1>
		<Section id="about">      
			<svg id="about" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#fdfdfd" fill-opacity="1" d="M0,128L120,106.7C240,85,480,43,720,42.7C960,43,1200,85,1320,106.7L1440,128L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"></path></svg>
			<StyledContainer>
			<Grid >
				<div>
				<Bold>
				<h2>Charging Where You Need It</h2>
				</Bold>
				<p>
				Utilizing existing infrastructure Polevolt helps bring charging points to your neighborhood.
				</p>
				<br/>
				<Item>
					<svg width="25" height="25" viewBox="0 0 103 103" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M94.8514 47.5619V51.5678C94.8461 60.9575 91.8056 70.0939 86.1835 77.6144C80.5614 85.1349 72.6588 90.6366 63.6545 93.2989C54.6501 95.9612 45.0264 95.6415 36.2186 92.3875C27.4108 89.1334 19.8908 83.1194 14.7802 75.2424C9.66961 67.3653 7.2422 58.0473 7.86001 48.6779C8.47782 39.3086 12.1078 30.39 18.2084 23.2522C24.3091 16.1144 32.5537 11.1399 41.7125 9.07059C50.8713 7.00127 60.4537 7.94801 69.0305 11.7696" stroke="#000" stroke-width="14.4615" stroke-linecap="round" stroke-linejoin="round"/><path d="M94.8519 16.7344L51.309 60.3208L38.2461 47.2579" stroke="#000" stroke-width="14.4615" stroke-linecap="round" stroke-linejoin="round"/></svg>
					<h4>Charge electric vehicles without the need to dig trenches or wire new circuts</h4>
				</Item>
				<Item>
					<svg width="25" height="25" viewBox="0 0 103 103" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M94.8514 47.5619V51.5678C94.8461 60.9575 91.8056 70.0939 86.1835 77.6144C80.5614 85.1349 72.6588 90.6366 63.6545 93.2989C54.6501 95.9612 45.0264 95.6415 36.2186 92.3875C27.4108 89.1334 19.8908 83.1194 14.7802 75.2424C9.66961 67.3653 7.2422 58.0473 7.86001 48.6779C8.47782 39.3086 12.1078 30.39 18.2084 23.2522C24.3091 16.1144 32.5537 11.1399 41.7125 9.07059C50.8713 7.00127 60.4537 7.94801 69.0305 11.7696" stroke="#000" stroke-width="14.4615" stroke-linecap="round" stroke-linejoin="round"/><path d="M94.8519 16.7344L51.309 60.3208L38.2461 47.2579" stroke="#000" stroke-width="14.4615" stroke-linecap="round" stroke-linejoin="round"/></svg>
					<h4>Help make your neighborhood more sustainable and green by reducing the need for gas powered vehicles.</h4>
				</Item>
				{/* <Item>
					<svg width="30" height="30" viewBox="0 0 103 103" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M94.8514 47.5619V51.5678C94.8461 60.9575 91.8056 70.0939 86.1835 77.6144C80.5614 85.1349 72.6588 90.6366 63.6545 93.2989C54.6501 95.9612 45.0264 95.6415 36.2186 92.3875C27.4108 89.1334 19.8908 83.1194 14.7802 75.2424C9.66961 67.3653 7.2422 58.0473 7.86001 48.6779C8.47782 39.3086 12.1078 30.39 18.2084 23.2522C24.3091 16.1144 32.5537 11.1399 41.7125 9.07059C50.8713 7.00127 60.4537 7.94801 69.0305 11.7696" stroke="#000" stroke-width="14.4615" stroke-linecap="round" stroke-linejoin="round"/><path d="M94.8519 16.7344L51.309 60.3208L38.2461 47.2579" stroke="#000" stroke-width="14.4615" stroke-linecap="round" stroke-linejoin="round"/></svg>
					<h4>Central place for your business</h4>
				</Item> */}
				</div>
				<Art>
				<Img fluid={data.art_story.childImageSharp.fluid} />
				</Art>
			</Grid>
			</StyledContainer>
		</Section>
    </Div1>
    )}
  />
);

const Div1 = styled.div`
// margin: 5rem 0rem;
z-index: 1;
margin-top: -300px;

//  margin-top: 10px;

 @media (max-width: ${props => props.theme.screen.md}) {
//   margin-top: 20px;
}
`;

const Bold = styled.div`
font-weight: bold;

 @media (max-width: ${props => props.theme.screen.md}) {
	font-weight: bold;
}
`;


// const White = styled.div`
//   margin-top: -10px;
//   margin-bottom: -150px;
//   width; 100vw;
//   height: 100px;
//   background-color: ${props => props.theme.color.ls.white};  
// `;

// const White1 = styled.div`
//   margin-top: -150px;
//   width; 100vw;
//   height: 100px;
//   background-color: ${props => props.theme.color.ls.white};  
// `;


// const LogoGrid = styled.div`
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   grid-gap: 64px;
//   justify-items: center;
//   margin-top: 96px;

//   a {
//     svg {
//       width: 100%;
//     }
//   }

//   @media (max-width: ${props => props.theme.screen.sm}) {
//     grid-template-columns: 1fr;
//   }
// `;

const Item = styled.div`
color: ${props => props.theme.color.ls.black};
display: grid;
grid-template-columns: 50px 1fr;
grid-gap: 0px;
text-align: left;
align-items: center;
justify-items: start;
margin: 20px 0px;

`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: flex-end;
  position: relative;

  @media (max-width: ${props => props.theme.screen.md}) {
    justify-content: center;
  }
`;

const Grid = styled.div`
  z-index: 1;
  width: 70vw;
  color: ${props => props.theme.color.ls.black};
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 40px;
  text-align: left;
  align-items: center;
  justify-items: center;
  margin: 24px 0;

  ${props =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 2fr 3fr;
  `}

  h2 {
    margin-bottom: 16px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    width: 80vw;

    margin-top: 300px;

    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }

    ${props =>
      props.inverse &&
      `
        ${Art} {
          order: 2;
        }
    `}
  }
`;

const Art = styled.figure`
  margin: 0;
  max-width: 380px;
  width: 100%;
`;



// const Div1 = styled.div`
//   margin-top: 750px;

//   z-index: 11;
//   background-color: transparent;

//   @media (max-width: ${props => props.theme.screen.md}) {
//     margin-top: 900px;
//   }
// `;

// const Item = styled.div`
//   z-index: 10;
//   height: 420px;
//   width: 300px;
// `;

// const S = styled.div`
//   border-radius: 50px;
//   background-color: ${props => props.theme.color.ls.shadow1};
//   box-shadow: 20px 20px 28px rgba(209, 217, 230, 0.36);
//   height: 370px;
//   width: 300px;
// `;

// const T = styled.div`
//   display: flex;
//   justify-content: center; 
//   align-items: center; 
//   box-shadow: inset 65.5541px 65.5541px 109.257px #D1D9E6;
//   border-radius: 50px 50px 0px 75px;
//   background-color: ${props => props.theme.color.ls.white};
//   height: 180px;
//   width: 300px;
// `;

// const Price = styled.div`
//   position: absolute;
//   display: flex;
//   align-items: flex-end;
//   flex-direction: row;
//   z-index: 5;

//   p1{
//     align-self: start;
//   }

//   h1 {
//     font-size: 70px;
//     font-weight: bold;
//   }
// `;

// const DotR = styled.div`
//   z-index: 1;
//   background: linear-gradient(90deg, #FD746C 0%, #FF9068 100%);
//   height: 130px;
//   width: 130px;
//   border-radius: 500px;
// `;

// const Dot = styled.div`
//   z-index: 1;
//   background: linear-gradient(90deg, #EDEDED 0%, #D1D9E6 100%);
//   height: 130px;
//   width: 130px;
//   border-radius: 500px;
// `;

// const B = styled.div`
//   height: 170px;
//   width: 262px;
//   margin-top: 10px;
//   margin-left: 20px;
// `;

// const BText = styled.div`
// text-align: left:
// font-weight: bold;
// font-size: 28px;
// line-height: 48px;
// text-transform: capitalize;
// color: ${props => props.theme.color.ls.black}
// `;

// const SText = styled.div`
// text-align: left:
// font-weight: regular;
// font-size: 16px;
// line-height: 22px;
// text-transform: capitalize;
// color: ${props => props.theme.color.ls.black}
// `;

// const M = styled.div`
// margin-top: 10px;
// border-radius: 500px;
// display: flex;
// justify-content: center;
// align-items: center;
// margin-left: 62.5px;
// text-align: center;
// width: 175px;
// height: 40px;
// `;

// const Grid = styled.div`
//   display: grid;
//   grid-template-columns: 1fr 1fr 1fr;
//   grid-gap: 40px;
//   text-align: left;
//   align-items: center;
//   justify-items: center;
//   margin: 24px 0;
  

//   ${props =>
//     props.inverse &&
//     `
//     text-align: left;
//     grid-template-columns: 2fr 3fr;
//   `}

//   h2 {
//     margin-bottom: 16px;
//   }

//   @media (max-width: ${props => props.theme.screen.md}) {
//     grid-template-columns: 1fr;
//     text-align: left;
//     margin-bottom: 96px;

//     &:last-child {
//       margin-bottom: 24px;
//     }

//     ${props =>
//       props.inverse &&
//       `
//         ${Art} {
//           order: 2;
//         }
//     `}
//   }
// `;

// const Art = styled.figure`
//   margin: 0;
//   max-width: 380px;
//   width: 100%;
// `;

export default Team;
