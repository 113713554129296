// import ExternalLink from '@common/ExternalLink';
import { Container } from '@components/global';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import React from 'react';
import { Link } from 'gatsby';


const Header = () => (
  <StaticQuery
    query={graphql`
      query {
        art_build: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "build" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <div>
      <Bottom/>

      <HeaderWrapper>
      <br/>
        <Container>
          <Grid>
            <Text>
              <Title>
                EV Charging Solutions Without The Hassle
                <br />
                
              </Title>
              <h4>
			  Add charging infrastructure without the need to wire new circuits with a Polevolt retrofit.
			  </h4>
              <br />
              <br />
			  <br />
              <HeaderButton>
                <p>
                  {/* <StyledExternalLink href=""> */}
                  <StyledExternalLink to="/get-started">
                  {/* &#x2795;  */}
				  <svg width="25" height="25" viewBox="0 0 103 103" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M94.8514 47.5619V51.5678C94.8461 60.9575 91.8056 70.0939 86.1835 77.6144C80.5614 85.1349 72.6588 90.6366 63.6545 93.2989C54.6501 95.9612 45.0264 95.6415 36.2186 92.3875C27.4108 89.1334 19.8908 83.1194 14.7802 75.2424C9.66961 67.3653 7.2422 58.0473 7.86001 48.6779C8.47782 39.3086 12.1078 30.39 18.2084 23.2522C24.3091 16.1144 32.5537 11.1399 41.7125 9.07059C50.8713 7.00127 60.4537 7.94801 69.0305 11.7696" stroke="#fff" stroke-width="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M94.8519 16.7344L51.309 60.3208L38.2461 47.2579" stroke="#fff" stroke-width="10" stroke-linecap="round" stroke-linejoin="round"/></svg>
				  <svg width="10" height="10" viewBox="0 0 103 103" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M94.8514 47.5619V51.5678C94.8461 60.9575 91.8056 70.0939 86.1835 77.6144C80.5614 85.1349 72.6588 90.6366 63.6545 93.2989C54.6501 95.9612 45.0264 95.6415 36.2186 92.3875C27.4108 89.1334 19.8908 83.1194 14.7802 75.2424C9.66961 67.3653 7.2422 58.0473 7.86001 48.6779C8.47782 39.3086 12.1078 30.39 18.2084 23.2522C24.3091 16.1144 32.5537 11.1399 41.7125 9.07059C50.8713 7.00127 60.4537 7.94801 69.0305 11.7696" stroke={props => props.theme.color.pv.button} stroke-width="14.4615" stroke-linecap="round" stroke-linejoin="round"/><path d="M94.8519 16.7344L51.309 60.3208L38.2461 47.2579" stroke={props => props.theme.color.pv.button} stroke-width="14.4615" stroke-linecap="round" stroke-linejoin="round"/></svg>
				  <h4>Get Started</h4>
                  </StyledExternalLink>
                </p>
              </HeaderButton>
            </Text>
            <Art>
              <IMM>
                <Img fluid={data.art_build.childImageSharp.fluid} />
              </IMM>
            </Art>
          </Grid>
        </Container>
      </HeaderWrapper>
      </div>
    )}
  />
);

const Bottom = styled.div`
  background: linear-gradient(to right,  #000 0%,#000 50%,#fff 50%,#fff 100%);
  height: 50rem;
  z-index: 0;
`

const Title = styled.div`
  ${props => props.theme.font_size.larger};
  margin-bottom: 10px;
  font-weight: bold;

  @media (max-width: ${props => props.theme.screen.md}) {
    font-weight: bold;
    ${props => props.theme.font_size.larger};
  }
`;

const HeaderWrapper = styled.header`
  background-color: ${props => props.theme.color.pv.black};
  transform: rotate(-5deg) skew(-5deg);
  margin: -50rem 0rem -2rem -2rem;
  padding-top: 96px;

  @media (max-width: ${props => props.theme.screen.md}) {
    padding-top: 128px;
  }
`;

const Art = styled.figure`
  width: 90%;
  margin: 0;

  > div {
    width: 100%;

    @media (max-width: ${props => props.theme.screen.md}) {
      width: 0%;
    }
  }
`;

const IMM = styled.div`
  height:100%;
  z-index: 1;

  @media (max-width: ${props => props.theme.screen.md}) {
    z-index: 1;  
  }
`;

const HeaderButton = styled.div`  
  box-shadow: 18px 18px 30px ${props => props.theme.color.pv.shadow};
  background: ${props => props.theme.color.pv.button};
  padding: 13px 30px 10px 5px;
  border-radius: 500px;
  width: fit-content;
  hight: 50px;
  z-index: 1;
`;

const Grid = styled.div`
  transform: rotate(5deg) skew(5deg);
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 64px;
  padding: 2rem;
  display: grid;

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 80px;

    > ${Art} {
      order: 2;
    }
  }
`;

const Text = styled.div`
  color: ${props => props.theme.color.pv.invText};
  background-color: transparent;
  justify-self: center;
  margin-top: 0px;

  @media (max-width: ${props => props.theme.screen.md}) {
    margin-top: 0px;
    justify-self: start;
  }
`;

const StyledExternalLink = styled(Link)`
  color: ${props => props.theme.color.ls.white};
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding-left: 20px;

  &:hover {
    color: ${props => props.theme.color.ls.whiteC};
  }
`;

export default Header;
