import logo from '../../../images/logos/logo.png';
import { Container } from '@components/global';
import styled from 'styled-components';

export const Nav = styled.nav`
  background-color: ${props => props.theme.color.pv.header};
  backdrop-filter: blur(3px);
  padding: 40px 0px;
  position: fixed;
  z-index: 1000;
  width: 100%;
  top: 0;

  @media (max-width: ${props => props.theme.screen.md}) {
    padding: 16px 0px;
  }
`;

export const StyledContainer = styled(Container)`
  justify-content: space-between;
  align-items: center;
  display: flex;
  `;

export const NavListWrapper = styled.div`
  ul {
    flex-direction: row;
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;

    ${({ mobile }) =>
      mobile &&
      `
        flex-direction: column;
        margin-top: 1em;

        > ${NavItem} {
          margin: 0.75em 0 0 0;
        }
      `};
  }
`;

export const NavItem = styled.li`
  font-family: ${props => props.theme.font.secondary};
  ${props => props.theme.font_size.small};
  margin: 0 0.75em;

  a {
    color: ${props => props.theme.color.pv.invText};
    text-decoration: none;
    opacity: 0.7;
  }

  &.active {
    a {
      opacity: 1;
    }
  }
`;

export const MobileMenu = styled.div`
  width: 100%;
`;

export const Brand = styled.div`
  font-family: ${props => props.theme.font.primary};
  color: ${props => props.theme.color.pv.invText};
  ${props => props.theme.font_size.large};
  align-items: center;
  display: flex;

  @media (max-width: ${props => props.theme.screen.md}) {
    ${props => props.theme.font_size.small};
  }

`;

export const Logo = styled.div`
  background-image: url(${logo});
  background-repeat: no-repeat;
  background-size: auto 100%;
  align-items: center;
  margin-right: 5px;
  max-height: 2rem;
  max-width: 2rem;
  display: flex;
  height: 5vw;
  width: 5vw;

  @media (max-width: ${props => props.theme.screen.md}) {
    max-height: 1.5rem;
    max-width: 1.5rem;
  }
`

export const Mobile = styled.div`
  display: none;

  @media (max-width: ${props => props.theme.screen.md}) {
    display: block;
  }

  ${props =>
    props.hide &&`
    display: block;

    @media (max-width: ${props.theme.screen.md}) {
      display: none;
    }
  `}
`;
