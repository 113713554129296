import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
// import Img from 'gatsby-image';

import { Section, Container } from '@components/global';

const About = () => (
  <StaticQuery
    query={graphql`
      query {
        art_fast: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "fast" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        art_learn: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "learn_yourself" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        art_ideas: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "ideas" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="features">
      {/* <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/> */}
      <Move>
        <Container>
        <h2>Learn More About The Project</h2>
        <br/>
        <Grid>
          {/* Reaserch Project */}
          <Item>
            <ItemBody>
              <Top>
                <BText>Project Origin</BText>
                <SText>Started in UNCC’s EPIC labratory, with funding from grants dedicated to EPIC, Polevolt is currently working with large energy utilites to begin pilot studies</SText>
              </Top>
              {/* <Center>
                photo
              </Center> */}
              <Bottom onClick={()=>window.location.href = "https://epic.charlotte.edu/"}>
                Learn More
              </Bottom>
            </ItemBody>
          </Item>
          {/* DOE Funded */}
          <Item>
            <ItemBody>
              <Top>
                {/* <BText>Department of Energy Funded</BText> */}
                {/* // <SText>Polevolt was funded in part by a grand from the United States Department of Energy</SText> */}
                <BText>Historic West End - Green District</BText>
                <SText>Transport is the second largest CO2 emitting sector in the United States. Working with the community we aim to bring infrastructure to reduce emissions and clean the air through the electrification of transport.</SText>
              </Top>
              {/* <Center>
                photo
              </Center> */}
              <Bottom onClick={()=>window.location.href = "https://www.charlotteobserver.com/news/business/development/article255570636.html"}>
			  	Learn More
              </Bottom>
            </ItemBody>
          </Item>
          {/* Microsoft */}
          <Item>
            <ItemBody>
              <Top>
                <BText>Microsoft's Collaboration with the Team</BText>
                <SText>Working with the Microsoft Azure team, polevolt has been able to develop a unique IoT based solution</SText>
              </Top>
              {/* <Center>
                photo
              </Center> */}
              <Bottom onClick={()=>window.location.href = "https://customers.microsoft.com/en-us/story/1374046673401312344-university-of-north-carolina-at-charlotte-higher-education-azure-en-united-states"}>
			  	Learn More
              </Bottom>
            </ItemBody>
          </Item>
        </Grid>
        </Container>
        </Move>
      </Section>
    )}
  />
);


const Move = styled.div`
margin-top: 300px;
z-index: 1;
//  margin-bottom: -100px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: .1rem;
  text-align: left;
  align-items: center;
  justify-items: center;
  margin: 24px 0;

  ${props =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 2fr 3fr;
  `}

  h2 {
    margin-bottom: 16px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }

    ${props =>
      props.inverse &&
      `
        ${Art} {
          order: 2;
        }
    `}
  }
`;

const Item = styled.div`
  justify-content:center;
  align-items: center;
  height: 25rem;
  display: flex;
  width: 20rem;
  z-index: 10;
`;

const ItemBody = styled.div`
  box-shadow: 0px 10px 20px ${props => props.theme.color.pv.shadow};
  background-color: ${props => props.theme.color.pv.white};
  border-radius: 50px;
  padding: 2rem;
  height: 22rem;
  width: 17rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  `;

  const Top = styled.div`
    
  `;
    const BText = styled.div`
      text-align: left:
      font-weight: 900;
      font-size: 1.5rem;
      line-height: 2rem;
      text-transform: capitalize;
      color: ${props => props.theme.color.pv.black}
    `;

    const SText = styled.div`
      text-align: left:
      font-weight: regular;
	  margin-top: 1rem;
      font-size: .8rem;
      line-height: 22px;
      color: ${props => props.theme.color.pv.black}
    `;

  const Center = styled.div`
  `;

  const Bottom = styled.div`
    display: flex;
    justify-content: center;
    background-color: ${props => props.theme.color.pv.button};
    border-radius: 50rem;
    padding: .8rem;
    color: ${props => props.theme.color.pv.invText};
  `;

const Art = styled.figure`
  margin: 0;
  max-width: 380px;
  width: 100%;
`;

export default About;
