import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';

import ExternalLink from '@common/ExternalLink';

const Header = () => (
  <StaticQuery
    query={graphql`
      query {
        art_build: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "build" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
		<HeaderWrapper id='contact'>
		  <Title>Ready To Get Charging?</Title>
          <h4>Click below to recommend a location for a Polevolt system</h4>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <SUP>
            <p>
              <StyledExternalLink href="/get-started">
              {/* <StyledExternalLink> */}
			    <svg width="25" height="25" viewBox="0 0 103 103" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M94.8514 47.5619V51.5678C94.8461 60.9575 91.8056 70.0939 86.1835 77.6144C80.5614 85.1349 72.6588 90.6366 63.6545 93.2989C54.6501 95.9612 45.0264 95.6415 36.2186 92.3875C27.4108 89.1334 19.8908 83.1194 14.7802 75.2424C9.66961 67.3653 7.2422 58.0473 7.86001 48.6779C8.47782 39.3086 12.1078 30.39 18.2084 23.2522C24.3091 16.1144 32.5537 11.1399 41.7125 9.07059C50.8713 7.00127 60.4537 7.94801 69.0305 11.7696" stroke="#fff" stroke-width="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M94.8519 16.7344L51.309 60.3208L38.2461 47.2579" stroke="#fff" stroke-width="10" stroke-linecap="round" stroke-linejoin="round"/></svg>
				<svg width="10" height="10" viewBox="0 0 103 103" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M94.8514 47.5619V51.5678C94.8461 60.9575 91.8056 70.0939 86.1835 77.6144C80.5614 85.1349 72.6588 90.6366 63.6545 93.2989C54.6501 95.9612 45.0264 95.6415 36.2186 92.3875C27.4108 89.1334 19.8908 83.1194 14.7802 75.2424C9.66961 67.3653 7.2422 58.0473 7.86001 48.6779C8.47782 39.3086 12.1078 30.39 18.2084 23.2522C24.3091 16.1144 32.5537 11.1399 41.7125 9.07059C50.8713 7.00127 60.4537 7.94801 69.0305 11.7696" stroke={props => props.theme.color.pv.button} stroke-width="14.4615" stroke-linecap="round" stroke-linejoin="round"/><path d="M94.8519 16.7344L51.309 60.3208L38.2461 47.2579" stroke={props => props.theme.color.pv.button} stroke-width="14.4615" stroke-linecap="round" stroke-linejoin="round"/></svg>
                <h4>Get Started With Polevolt</h4>
              </StyledExternalLink>
            </p>
          </SUP>

          {/* <Email>
            <Box>enter your email adress</Box>
            <In><Button>&nbsp;&#x2794;</Button></In>
          </Email> */}

      </HeaderWrapper>
    )}
  />
);

const Title = styled.div`
  ${props => props.theme.font_size.xlarge};
  font-weight: bold;
  margin-bottom: 10px;

  @media (max-width: ${props => props.theme.screen.md}) {
    font-weight: bold;
    ${props => props.theme.font_size.larger};
  }
`;

//  background-color: ${props => props.theme.color.ls.white};
const HeaderWrapper = styled.header`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 250px;
  padding-top: 96px;
  background-color: ${props => props.theme.color.ls.white};

  @media (max-width: ${props => props.theme.screen.md}) {
    margin: 2rem;
    margin-top: 450px;
    padding-top: 128px;
  }
`;

// const Email = styled.div`
//   display: flex;
//   justify-content: center;
//   width: 100vw;
// `;

// const Box = styled.div`
//   margin-top: 120px;
//   width: 100vw;
//   max-width: 800px;
//   padding: 15px;
//   border-radius: 500px;
//   background: #FFFFFF;
//   box-shadow: 18px 18px 30px #D1D9E6;

//   @media (max-width: ${props => props.theme.screen.md}) {
//     max-width: 450px;
//   }
// `;

// const In = styled.div`
//   position: absolute;
//   display: flex;
//   justify-content: flex-end;
//   width: 100vw;
//   max-width: 800px;
//   padding: 15px;
//   border-radius: 500px;

//   @media (max-width: ${props => props.theme.screen.md}) {
//     max-width: 450px;
//   }
// `;

// const Button = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   color: ${props => props.theme.color.ls.white};
//   font-size: 30px;
//   border-radius: 500px;
//   margin-right: 80px;
//   margin-top: 130px;
//   width: 100px;
//   height: 100px;
//   background: linear-gradient(90deg, ${props => props.theme.color.pv.button} 0%, ${props => props.theme.color.pv.button} 100%);

//   @media (max-width: ${props => props.theme.screen.md}) {
//     margin-right: 30px;
//     width: 80px;
//     height: 80px;  
//     font-size: 25px;
//   }
// `;

const SUP = styled.div`
  text-align: center;
  z-index: 1;
  padding: 10px 10px 10px 0px;
  hight: 50px;
//   width: 400px;
  background: linear-gradient(90deg, ${props => props.theme.color.pv.button} 0%, ${props => props.theme.color.pv.button} 100%);
  box-shadow: 18px 18px 30px #D1D9E6, -18px -18px 30px #FFFFFF;
  border-radius: 500px;
`;

const StyledExternalLink = styled(ExternalLink)`
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.color.ls.white};
  text-decoration: none;

  &:hover {
    color: ${props => props.theme.color.ls.whiteC};
  }
`;

export default Header;
